import { MM_TUTORIAL_STEPS } from "#/utils/menu-match.utils";
import { OrderViewType } from "#/utils/order.utils";
import { atom } from "jotai";
import { RESET, atomWithStorage, useAtomCallback } from "jotai/utils";
import { useCallback } from "react";

export enum OrderSortType {
  LATEST = "latest",
  FULFILLMENT = "fulfillment",
}

export const initiallySelectedModifierGroup = {
  _id: "select_all_option",
  label: "All",
  modifier_ids: [],
  name: "All",
  value: "select_all_option",
  max_free: null,
  min_unique: null,
  max_unique: null,
};

/** @TODO type all the null states */
export const atoms = {
  applyAllLocationsChanges: atom<boolean>(false),
  nestedCategoriesVisibility: atom({}),
  raycast: atom(false),
  raycastIndex: atom(0),
  raycastSelected: atom({}),
  // auth states
  user: atomWithStorage("user", null),
  mounted: atom(false),
  mid: atomWithStorage("mid", null),
  accessToken: atomWithStorage("accessToken", null),
  orgId: atomWithStorage("orgId", null),
  orderSourcesFilter: atomWithStorage("orderSourcesFilter", []),
  // app states
  orderViewType: atomWithStorage<OrderViewType>(
    "orderViewType",
    OrderViewType.list,
  ),
  menuViewType: atomWithStorage<"list" | "cards">("menuViewType", "cards"),
  orderTooltip: atomWithStorage("orderTooltip", false),
  editScheduleVisible: atomWithStorage("editScheduleVisible", false),
  editLocationVisible: atomWithStorage("editLocationVisible", false),
  confirmLocationVisible: atomWithStorage("confirmLocationVisible", false),
  currentLocationId: atomWithStorage("currentLocationId", null),
  currentMenuId: atomWithStorage("currentMenuId", null),
  pendingPosIntegration: atomWithStorage("pendingPosIntegration", null),
  pendingOAuth: atomWithStorage("pendingOAuth", false),
  pendingDspIntegration: atomWithStorage("pendingDspIntegration", null),
  refreshIntegration: atomWithStorage("refreshIntegration", null),
  currentCategory: atom(0),
  dspOnboardingStep: atomWithStorage("dspOnboardingStep", null),
  posOnboardingStep: atomWithStorage("posOnboardingStep", null),
  pendingDSP: atomWithStorage("pendingDSP", null),
  currentCatalogId: atomWithStorage("currentCatalogId", null),
  locationToAddDspTo: atomWithStorage("locationToAddDspTo", null),
  merchantToAddPosTo: atomWithStorage("merchantToAddPosTo", null),
  selectedModifierGroup: atomWithStorage(
    "currentMG",
    initiallySelectedModifierGroup,
  ),
  // global modals
  mmModalStep: atomWithStorage("mmModalStep", null),
  optInMM: atomWithStorage("optInMM", null),
  mmTutorialStep: atomWithStorage("mm_step", MM_TUTORIAL_STEPS.welcome),
  orderSortType: atomWithStorage<OrderSortType>(
    "orderSortType",
    OrderSortType.LATEST,
  ),
};

export const useResetAtoms = () => {
  const resetAtoms = useAtomCallback(
    useCallback((_, set) => {
      Object.keys(atoms).forEach((atomKey) => {
        if (
          atomKey.includes("ModalVisible") ||
          atomKey.includes("isPublishing") ||
          atomKey.includes("raycast") ||
          atomKey.includes("mounted") ||
          atomKey.includes("addVirtualLocation") ||
          atomKey.includes("addCategoryModalVisible")
        ) {
          set(atoms[atomKey], false);
        } else if (
          atomKey.includes("raycastSelected") ||
          atomKey.includes("currentCategory")
        ) {
          set(atoms[atomKey], 0);
        } else if (atomKey.includes("mmTutorialStep")) {
          set(atoms[atomKey], MM_TUTORIAL_STEPS.welcome);
        } else {
          set(atoms[atomKey], RESET);
        }
      });
    }, []),
  );

  return resetAtoms;
};
