import { svgs } from "#/ui/svgs";

type SVGIconProps = {
  className: string;
  onClick?: () => void;
  svg:
    | "largeArrow"
    | "add-person"
    | "percentage"
    | "magic"
    | "dollar"
    | "chevronDouble"
    | "type"
    | "menu"
    | "chevronWide"
    | "note"
    | "circle"
    | "pins"
    | "arrowControls"
    | "list"
    | "mm-connect"
    | "grabber"
    | "checkList"
    | "chevronThickDown"
    | "grid"
    | "doubleCheck"
    | "success"
    | "error"
    | "staggered"
    | "categories"
    | "receipt"
    | "link"
    | "location"
    | "id"
    | "kanban";
};

export const SVGIcon = ({
  className,
  svg,
  onClick,
  ...props
}: SVGIconProps) => {
  return (
    <button className={className} onClick={onClick} {...props}>
      {svgs[svg]}
    </button>
  );
};
