import { components } from "#/types.ts/swagger";
import { cloneDeep } from "lodash";

export enum Unit {
  inch = "inch",
  oz = "oz",
  lbs = "lbs",
  gm = "gm",
}

export enum PublishStatus {
  /**
   *
   */
  pending = "pending",
  removed = "removed",
  failed = "failed",
  complete = "complete",
  not_published = "not_published",
}

export enum SAVE_CATALOG_FLAG {
  all = "all_locations",
  location = "specific_locations",
  no_override = "no_overrides",
}

export const isRequireMenuRefresh = (
  construct: components["schemas"]["CatalogUpdateDto"]["update_data"] & {
    flag?: components["schemas"]["CatalogUpdateDto"]["flag"];
    publish?: components["schemas"]["CatalogUpdateDto"]["publish"];
    force?: boolean;
  },
) => {
  if (construct?.force) {
    return true;
  }

  if (
    construct?.item_family?.some(
      (item) => item?.modifier_group_overrides?.length > 0,
    )
  ) {
    return true;
  }

  if (
    construct?.item_family?.some(
      (item) => item?.dsp_is_active_overrides?.length > 0,
    )
  ) {
    return true;
  }

  if (
    construct?.item_family?.some((item) => item?.modifier_group_ids?.length > 0)
  ) {
    return true;
  }

  if (
    construct?.item_family?.some((item) => item?.ordered_item_ids?.length > 0)
  ) {
    return true;
  }

  if (
    Object.keys(construct).some((key) => {
      if (key in construct && typeof construct[key] === "object") {
        return construct[key]?.some((item) => !!item?.delete_time);
      }
    })
  ) {
    return true;
  }

  return false;
};

export function isSortableListUpdate(updateData) {
  if (
    updateData?.modifier_group?.length === 1 &&
    updateData?.modifier_group?.[0]?.modifier_ids
  ) {
    return true;
  }
  return false;
}

export function updateConstruct(raw, newData) {
  /** we set this to true if any field signals a refresh needed */
  let needs_force_refresh = false;

  const construct = cloneDeep(raw);

  const objectIndexMap = Object.keys(construct).reduce((acc, key) => {
    if (Array.isArray(construct[key])) {
      if (!acc[key]) acc[key] = {};
      construct[key].forEach((item, index) => {
        acc[key][item._id] = index;
      });
    }
    return acc;
  }, {});

  Object.keys(newData).forEach((key) => {
    if (construct[key]) {
      newData[key].forEach((updatedItem) => {
        /** updates applied to cache */
        const updatesToApply = {};
        const itemIndexMap = objectIndexMap[key];
        const itemIndex = itemIndexMap[updatedItem._id];
        const sourceItem = construct[key][itemIndex];
        if (itemIndex !== -1) {
          Object.keys(updatedItem).forEach((key) => {
            if (key.endsWith("_ids") && key !== "modifier_ids") {
              if (!sourceItem) return;
              const sourceObjectIds = sourceItem[key];
              const updatedObjectIds = updatedItem[key];
              const newObjectIds = updatedObjectIds.filter(
                (id) => !sourceObjectIds?.includes(id),
              );

              const missingObject = newObjectIds.find(
                (object_id) => !itemIndexMap[object_id],
              );

              if (missingObject) {
                needs_force_refresh = true;
              } else {
                updatesToApply[key] = updatedItem[key];
              }
            } else if (key === "default_modifier_quantities") {
              updatesToApply[key] = updatedItem[key].reduce((acc, item) => {
                acc[item.modifier_id] = item.quantity;
                return acc;
              }, {});
            } else {
              updatesToApply[key] = updatedItem[key];
            }
          });

          // apply updated items to cache
          construct[key][itemIndex] = {
            ...sourceItem,
            ...updatesToApply,
          };
        }
      });
    }
  });

  return { needs_force_refresh, construct };
}

export enum SnoozeTypes {
  custom = "custom",
  one_week = "one_week",
  until_next_open = "until_next_open",
}

export enum MenuPublishFailureCode {
  /**
   * stream validation specific
   */
  MENU_CATALOG_MISMATCH = "MENU_CATALOG_MISMATCH",
  /** menu hours are not populated */
  MENU_SCHEDULE_MISSING = "MENU_SCHEDULE_MISSING",
  NO_MENUS_ASSIGNED = "NO_MENUS_ASSIGNED",
  MENU_ALREADY_PROCESSING = "MENU_ALREADY_PROCESSING",

  /**
   *  dsp validation specific
   */
  DSP_REJECTED = "DSP_REJECTED",
  DSP_THROTTLED_REQUEST = "DSP_THROTTLED_REQUEST",
  DSP_UNAUTHORIZED = "DSP_UNAUTHORIZED",
  DSP_FORBIDDEN = "DSP_FORBIDDEN",
  DSP_UNKNOWN = "DSP_UNKNOWN",
  MENU_ITEMS_PRICE_TOO_HIGH = "MENU_ITEMS_PRICE_TOO_HIGH",
  TAX_RATE_TOO_HIGH = "TAX_RATE_TOO_HIGH",
  INVALID_TAX_RATE = "INVALID_TAX_RATE",

  /** dsp issue, retry publish  */
  DSP_INTERNAL_FAILURE = "DSP_INTERNAL_FAILURE",
  // MISSING_SCHEDULE = 'MISSING_SCHEDULE',
  /** menu contains no data / items */
  MENU_BLANK = "MENU_BLANK",
  /** menu content contains issues, need to be investigatred */
  MENU_CONTENT_ISSUE = "MENU_CONTENT_ISSUE",
  /**
   * Menu contains items not allowed to be published
   * ex. weapons?
   */
  MENU_COMPLIANCE_ISSUE = "MENU_COMPLIANCE_ISSUE",
  MENU_FAILURE_UNKNOWN = "MENU_FAILURE_UNKNOWN",

  /**
   * Onboarding publish errors below
   */
  /** dsp store to integrate is not found */
  DSP_ACCOUNT_NOT_FOUND = "DSP_ACCOUNT_NOT_FOUND",
  DSP_LOCATION_IS_NOT_ELIGIBLE = "DSP_LOCATION_IS_NOT_ELIGIBLE",
  /** dsp store can not be integrated for a non unique location id */
  DUPLICATE_LOCATION_ID = "DUPLICATE_LOCATION_ID",
  /** dsp store is already activated */
  DSP_LOCATION_ALREADY_ACTIVE = "DSP_LOCATION_ALREADY_ACTIVE",
  ONBOARDING_FAILURE_UNKNOWN = "ONBOARDING_FAILURE_UNKNOWN",
  OUTDATED_HEAD = "OUTDATED_HEAD",
  UPDATE_ON_UNPUBLISHED = "UPDATE_ON_UNPUBLISHED",
}
