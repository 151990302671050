import { FetchError } from "#/lib/stream-api";
import { QueryClient } from "@tanstack/react-query";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      // @ts-ignore
      suspense: true,
      retry: (failureCount, error) => {
        if (failureCount > 5) return false;
        if (error instanceof FetchError) {
          if ([400, 401, 403, 404].includes(error?.response?.status)) {
            return false;
          }
        }
        return true;
      },
    },
  },
});
